<template>
  <div class="page">
    <!-- 详情box -->
    <div class="detail_box">
      <div class="in_detail_box">
        <div class="box_t">
          <div class="status_box">
            <p v-if="detail.status == 1"><span class="red"></span>待受理</p>
            <p v-else-if="detail.status == 2">
              <span class="blue"></span>已接单
            </p>
            <p v-else-if="detail.status == 3">
              <span class="yellow"></span>处理中
            </p>
            <p v-else><span class="green"></span>已完成</p>
          </div>
          <div class="time">{{ detail.createTime }}</div>
        </div>
        <div class="box_c">
          <p>
            工单编号：<span>{{ detail.id }}</span>
          </p>
          <p>
            工单类型：<span>{{ detail.workTypeName }}</span>
          </p>
          <p>
            事件类型：<span>{{ detail.incidentName }}</span>
          </p>
        </div>
        <div class="pic_box">
          <div
            class="img_box"
            v-for="(item, index) in this.detail.eventPicture"
            :key="index"
          >
            <img :src="item" alt="" />
          </div>
        </div>
        <div class="box_b">
          <p>
            事件描述：<span>{{ detail.eventDescription }}</span>
          </p>
          <p>
            发布人：<span>{{ detail.name }}</span
            ><img src="./img/workOrderDetail/phone.png" alt="" />
          </p>
          <p>
            事件地点：<span>{{ detail.eventPlace }}</span
            ><img src="./img/workOrderDetail/place_icon.png" alt="" />
          </p>
        </div>
      </div>
    </div>
    <!-- 回复内容 -->
    <div class="reply_box" v-if="detail.status != 4">
      <div class="in_reply_box">
        <p>回复内容</p>
        <div class="input_box" v-if="detail.status == 2">
          <van-field
            style="background: transparent; height: 100% !important"
            v-model="message"
            type="textarea"
            maxlength="30"
            placeholder="您的工单正在处理"
            show-word-limit
          />
        </div>
        <div class="input_box" v-if="detail.status == 1">
          <van-field
            style="background: transparent; height: 100% !important"
            v-model="message"
            type="textarea"
            maxlength="30"
            placeholder="您的工单已接收，稍后为您安排工作人员为您服务"
            show-word-limit
          />
        </div>
        <div class="input_box" v-if="detail.status == 3">
          <van-field
            style="background: transparent; height: 100% !important"
            v-model="message"
            type="textarea"
            maxlength="30"
            placeholder="服务已完毕，请为本次服务打分/评价"
            show-word-limit
          />
          <v-upload
            class="oItem-b_upload"
            :imgUrl.sync="editPhoto"
            :activityPicture.sync="editPhoto"
            :maxCount="3"
            ref="load"
          ></v-upload>
        </div>
      </div>
    </div>
    <!-- 订单评价 -->
    <div class="order_evaluate_box" v-if="detail.status == 4">
      <div class="in_order_evaluate_box">
        <div class="title">订单评价</div>
        <p>满意度：{{ this.satisfactionFormat(detail.satisfaction) }}</p>
        <p>意见建议：{{ detail.suggestion }}</p>
        <div class="img" v-if="detail.editPhoto && detail.editPhoto.length > 0">
          <img
            :src="item"
            alt=""
            v-for="(item, index) in detail.editPhoto.split(',')"
            :key="index"
          />
        </div>
      </div>
    </div>
    <!-- 处理/完成按钮 -->
    <div class="handle_btn" v-if="detail.status == 2">
      <div class="in_box" @click="manageWorkOrder(3, '处理')">处理</div>
    </div>
    <div class="handle_btn" v-if="detail.status == 3">
      <div class="in_box" @click="manageWorkOrder(4, '完成')">完成</div>
    </div>
    <!-- 转派/接单按钮 -->
    <div class="delivery_btn" v-if="detail.status == 1">
      <div class="in_box" @click="reassignment(detail.id)">转派</div>
    </div>
    <div class="order_receiving_btn" v-if="detail.status == 1">
      <div class="in_box" @click="manageWorkOrder(2, '接单')">接单</div>
    </div>
    <v-dialog
      confirmButtonColor="#3680FF"
      v-model="isDialog"
      title="提示"
      @confirm="manageWorkOrder"
    >
      <p class="transfer_message">是否{{ title }}？</p>
    </v-dialog>
  </div>
</template>

<script>
import { manageWorkOrderUrl, workOrderDetailsUrl } from "./api.js";
export default {
  name: "workOrderDetail",
  data() {
    return {
      message: "",
      detail: {},
      id: null,
      isDialog: false,
      title: "",
      manageWorkOrderStatus: null,

      editPhoto: "",
    };
  },
  computed: {
    userId() {
      return this.$store.state.userId;
    },
  },
  created() {
    this.id = this.$route.query.id;
  },
  mounted() {
    this.getDetailData();
  },
  methods: {
    manageWorkOrder(status, name) {
      //处理/完成/接单
      if (this.isDialog == false) {
        this.title = name;
        this.manageWorkOrderStatus = status;
        this.isDialog = true;
      } else {
        let params = {
          id: Number(this.id),
          type: this.manageWorkOrderStatus,
          response: this.message,
          editPhoto: this.editPhoto,
        };
        this.$axios
          .post(`${manageWorkOrderUrl}`, this.$qs.stringify(params))
          .then((res) => {
            if (res.code === 200) {
              this.$router.push({
                name: "myWorkOrder",
              });
            }
          });
      }
    },
    satisfactionFormat(num) {
      if (num == 1) {
        return "非常满意";
      } else if (num == 2) {
        return "满意";
      } else if (num == 3) {
        return "不满意";
      }
    },
    reassignment(id) {
      //转派
      this.$router.push({
        name: "personnelTransfer",
        query: {
          id: id,
        },
      });
    },
    getDetailData() {
      //获取订单详情
      let params = {
        id: this.id,
        userId: this.userId,
      };
      this.$axios
        .post(`${workOrderDetailsUrl}`, this.$qs.stringify(params))
        .then((res) => {
          if (res.code === 200) {
            res.data.eventPicture = res.data.eventPicture.split(",");
            this.detail = res.data;
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  width: 100%;
  min-height: 100vh;
  background: #fafafa;
  padding-top: 30px;
  box-sizing: border-box;

  .transfer_message {
    text-align: center;
    font-size: 30px;
    color: rgba(0, 0, 0, 0.5);
    padding: 20px 0;
  }

  .order_receiving_btn {
    height: 70px;
    box-sizing: border-box;
    padding: 0 100px;
    line-height: 70px;
    margin-top: 38px;

    .in_box {
      width: 100%;
      height: 100%;
      text-align: center;
      background: linear-gradient(180deg, #72a8ff 0%, #3680ff 100%);
      border-radius: 10px;
      font-size: 32px;
      font-weight: 600;
      color: #ffffff;
    }
  }

  .delivery_btn {
    height: 70px;
    box-sizing: border-box;
    padding: 0 100px;
    line-height: 70px;
    margin-top: 68px;

    .in_box {
      width: 100%;
      height: 100%;
      text-align: center;
      border-radius: 10px;
      border: 2px solid;
      font-size: 32px;
      font-weight: 600;
      color: #5594ff;
    }
  }

  .handle_btn {
    height: 70px;
    box-sizing: border-box;
    padding: 0 100px;
    line-height: 70px;
    margin-top: 122px;

    .in_box {
      width: 100%;
      height: 100%;
      text-align: center;
      background: linear-gradient(180deg, #72a8ff 0%, #3680ff 100%);
      border-radius: 10px;
      font-size: 32px;
      font-weight: 600;
      color: #ffffff;
    }
  }

  .order_evaluate_box {
    width: 100%;
    box-sizing: border-box;
    padding: 0 36px;

    .in_order_evaluate_box {
      width: 100%;
      min-height: 346px;
      background: #ffffff;
      box-shadow: 0px 4px 16px 0px rgba(199, 198, 198, 0.5);
      border-radius: 16px;
      box-sizing: border-box;
      padding: 20px 36px;
      .img {
        margin-top: 10px;
        width: 100%;
        height: 186px;
        display: flex;
        justify-content: space-between;
        img {
          width: 186px;
          height: 100%;
          border-radius: 10px;
        }
      }

      .title {
        font-size: 32px;
        font-weight: 600;
        color: #000000;
        margin-bottom: 22px;
      }

      p {
        font-size: 26px;
        color: rgba(0, 0, 0, 0.85);
      }
    }
  }

  .reply_box {
    width: 100%;
    box-sizing: border-box;
    padding: 0 36px;

    .in_reply_box {
      width: 100%;
      min-height: 346px;
      background: #ffffff;
      box-shadow: 0px 4px 16px 0px rgba(199, 198, 198, 0.5);
      border-radius: 16px;
      padding: 20px 28px 36px 24px;
      box-sizing: border-box;

      p {
        font-size: 32px;
        font-weight: 600;
        color: #000000;
        padding-left: 16px;
        margin-bottom: 22px;
      }

      .input_box {
        width: 100%;
        min-height: 224px;
        background: rgba(206, 202, 202, 0.1);
        border-radius: 16px;
        overflow: hidden;

        .van-cell {
          padding: 30px 20px;
        }
      }
    }
  }

  .detail_box {
    width: 100%;
    box-sizing: border-box;
    padding: 0 36px;
    margin-bottom: 34px;

    .in_detail_box {
      width: 100%;
      box-sizing: border-box;
      padding: 28px 36px 0;
      background: #ffffff;
      box-shadow: 0px 4px 16px 0px rgba(199, 198, 198, 0.5);
      border-radius: 16px;

      .box_b {
        box-sizing: border-box;
        padding-left: 4px;
        padding-bottom: 38px;

        p {
          font-size: 28px;
          color: rgba(0, 0, 0, 0.5);
          margin-bottom: 10px;
          display: flex;
          align-items: center;

          span {
            color: #333333;
          }
        }

        p:nth-child(2) {
          img {
            width: 28px;
            height: 26px;
            margin-left: 26px;
          }
        }

        p:nth-child(3) {
          img {
            width: 26px;
            height: 24px;
            margin-left: 18px;
          }
        }

        p:nth-last-child(1) {
          span {
            font-weight: 600;
          }
        }
      }

      .pic_box {
        width: 100%;
        height: 186px;
        margin-bottom: 26px;
        padding-left: 8px;
        display: flex;
        justify-content: space-between;

        img {
          width: 186px;
          height: 186px;
          border-radius: 10px;
        }
      }

      .box_c {
        box-sizing: border-box;
        padding: 20px 6px;

        p {
          font-size: 28px;
          color: rgba(0, 0, 0, 0.5);
          margin-bottom: 10px;

          span {
            color: #333333;
          }
        }

        p:nth-last-child(1) {
          margin-bottom: 2px;
        }
      }

      .box_t {
        width: 100%;
        height: 64px;
        display: flex;
        box-sizing: border-box;
        border-bottom: 2px solid rgba(0, 0, 0, 0.1);

        .time {
          flex: 1;
          font-size: 26px;
          color: rgba(0, 0, 0, 0.41);
          text-align: right;
          line-height: 64px;
        }

        .status_box {
          flex: 1;
          font-size: 26px;
          font-weight: 600;
          color: rgba(0, 0, 0, 0.85);
          display: flex;
          align-items: center;
          margin-left: 8px;

          p {
            display: flex;
            align-items: center;

            span {
              width: 10px;
              height: 10px;
              border-radius: 50%;
              margin-right: 10px;
              display: inline-block;
            }

            .red {
              background: #c33333;
            }

            .blue {
              background: #8db0e8;
            }

            .yellow {
              background: #f9980b;
            }

            .green {
              background: #66b95a;
            }
          }
        }
      }
    }
  }
}
</style>
